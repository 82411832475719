
export default {
  props: {
    titre: {
      type: String,
      required: true
    },
    texte: {
      type: String,
      default: null
    },
    texteLien: {
      type: String,
      required: true
    },
    destinationLien: {
      type: String,
      required: true
    },
    icone: {
      type: String,
      required: true
    }
  }
}
