
export default {
  name: 'Prefooter',
  props: {
    prefooter: {
      type: Object,
      required: true
    }
  },
  computed: {
    image () {
      return '/directus/assets/' + this.prefooter.image.image.id
    },
    imagePygma () {
      return {
        backgroundImage: 'url(' + this.image + ')'
      }
    },
    couleurTexte () {
      const fond = this.prefooter.image.couleur_de_fond
      return 'texte--' + ((fond.includes('bleu-marine')) ? 'blanc' : 'bleu-marine')
    },
    texte () {
      return this.prefooter.translations[0].texte
    }
  }
}
