
export default {
  name: 'CarteAccueil',
  props: {
    icone: {
      type: String,
      required: true
    },
    preTitre: {
      type: String,
      required: true
    },
    titre: {
      type: String,
      required: true
    },
    texte: {
      type: String,
      required: true
    },
    titreLien: {
      type: String,
      required: true
    },
    lien: {
      type: String,
      required: true
    },
    imageCouleurFond: {
      type: String,
      required: true
    },
    imageVersGauche: {
      type: Boolean,
      default: false
    },
    imageId: {
      type: String,
      required: true
    }
  },
  computed: {
    imageStyle () {
      return {
        height: 'clamp(18.125rem, 13.893rem + 21.161vw, 32.938rem)',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-image': 'url(/directus/assets/' + this.imageId + ')'
      }
    }
  }
}
