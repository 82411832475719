
export default {
  name: 'RegroupementLiensBasDePage',
  props: {
    liens: {
      type: Array,
      required: true
    }
  }
}
