
import { langue } from '~/assets/js/utiles.js'

export default {
  async asyncData ({ params, app, store, $directus }) {
    const langueTraduction = langue(app.i18n.locale)
    const traduction = {
      translations: {
        _filter: {
          languages_code: {
            _eq: langueTraduction
          }
        }
      }
    }

    const collectionPrefooter = $directus.items('prefooter')
    const prefooters = await collectionPrefooter.readByQuery({
      fields: [
        'est_pygma',
        'image.couleur_de_fond',
        'image.image.id',
        'translations.texte'
      ],
      filter: {
        afficher_accueil: {
          _eq: true
        }
      },
      deep: traduction
    })

    const collectionCartes = $directus.items('carte_accueil')
    const cartes = await collectionCartes.readByQuery({
      fields: [
        'icone',
        'image.couleur_de_fond',
        'image.image.id',
        'translations.pre_titre',
        'translations.titre',
        'translations.titre_lien',
        'translations.lien',
        'translations.texte'
      ],
      filter: {
        afficher: {
          _eq: true
        }
      },
      deep: traduction
    })

    const collectionLiens = $directus.items('lien_bas_page')
    const liens = await collectionLiens.readByQuery({
      fields: [
        'icone',
        'translations.titre',
        'translations.texte',
        'translations.texte_lien',
        'translations.destination_lien'
      ],
      filter: {
        est_visible: {
          _eq: true
        }
      },
      deep: traduction
    })

    const collectionEntete = $directus.items('bloc_entete')
    const entetes = await collectionEntete.readByQuery({
      fields: [
        'image.couleur_de_fond',
        'image.image.id',
        'translations.texte',
        'translations.titre'
      ],
      filter: {
        afficher_accueil: {
          _eq: true
        }
      },
      deep: traduction
    })

    if (Object.keys(cartes.data).length === 0) {
      throw new Error('Carte d\'accueil introuvable')
    } else if (Object.keys(prefooters.data).length === 0) {
      throw new Error('Prefooter introuvable')
    } else if (Object.keys(liens.data).length === 0) {
      throw new Error('Liens bas de page introuvables')
    } else if (Object.keys(entetes.data).length === 0) {
      throw new Error('Entete introuvable')
    }

    return {
      cartes: cartes.data,
      prefooter: prefooters.data[0],
      liens: liens.data,
      entete: entetes.data[0]
    }
  },
  head () {
    const i18nHeaders = this.$nuxtI18nHead({ addSeoAttributes: true })

    return {
      title: "service régional d'admission du montréal métropolitain",
      htmlAttrs: {
        ...i18nHeaders.htmlAttrs
      },
      meta: [
        { hid: 'description', name: 'description', content: this.$t('description_seo') },
        ...i18nHeaders.meta
      ],
      link: [
        ...i18nHeaders.link
      ]
    }
  }
}
