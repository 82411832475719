
export default {
  name: 'BlocAccueilEntete',
  props: {
    blocEntete: {
      type: Object,
      required: true
    }
  },
  computed: {
    image () {
      return {
        'background-image': 'url(/directus/assets/' + this.blocEntete.image.image.id + ')'
      }
    }
  }
}
