import { render, staticRenderFns } from "./LienBasDePage.vue?vue&type=template&id=2dbba5c2&scoped=true&"
import script from "./LienBasDePage.vue?vue&type=script&lang=js&"
export * from "./LienBasDePage.vue?vue&type=script&lang=js&"
import style0 from "./LienBasDePage.vue?vue&type=style&index=0&id=2dbba5c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbba5c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icone: require('/usr/src/nuxt-app/components/Icone.vue').default})
